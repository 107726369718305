@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.privileges {
    position: relative;

    .backgroundImage {
        padding-top: 25px;
        padding-bottom: res(55, 85);
        background: url('./background.jpg') no-repeat center center / cover;
    }

    h2 {
        margin-bottom: 17px;
        font-weight: 300;
        font-size: 40px;
        text-align: center;
        color: $color-advisors-text;
    }

    .privilegesWrapper {
        
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: res(25, 40);
        border-top: 1px solid $color-light-grey;

        .mainImage {
            margin: 0 30px;
        }

        ul {
            position: relative;
            z-index: 1;

            h4 {
                margin-bottom: 3px;
                font-weight: 500;
                font-size: 16px;
                line-height: 1.1;
            }

            &.privilegesTextLeft {
                text-align: right;
            }

            &.privilegesTextRight {
                text-align: left;
            }

            li {
                margin-bottom: 78px;
            }
        }
    }

    @include desktop {
        .privilegesWrapper .mainImage {
            .mobile {
                display: none;
            }
            .desktop {
                display: block;
            }
        }
    }

    @include mobile {

        .backgroundImage {
            height: 558px;
            margin: 0 21px 55px 22px;
            padding-bottom: 0;
        }

        .privilegesWrapper {
            justify-content: space-between;

            .mainImage {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                margin: 0;
                max-width: 367px;
                width: 100%;

                .mobile {
                    display: block;
                }
                .desktop {
                    display: none;
                }
            }

            ul {
                li {
                    max-width: 137px;
                    margin-bottom: 102px;
                }

                &.privilegesTextLeft {
                    text-align: left;
                }
    
                &.privilegesTextRight {
                    text-align: right;
                }
            }
        }
    }
}