@import './variables';

// desktop-lg
@mixin desktop-lg {
    @media screen and (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// desktop
@mixin desktop {
    @media screen and (min-width: #{$screen-sm-min}) {
        @content;
    }
}

//tablet
@mixin tablet {
    @media screen and (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// mobile
@mixin mobile {
    @media screen and (max-width: #{$screen-sm-max}) {
        @content;
    }
}