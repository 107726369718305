@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.promo {
    position: relative;
    padding: res(74, 159) 0 res(100, 194) res(20, 41);
    background: url('./images/background.jpg') no-repeat center center / cover;

    > div {
        max-width: 1244px;
    }

    .mainWrapper {
        color: $white;
        max-width: 510px;

        > span {
            font-size: 18px;
            line-height: 1.92;
        }

        h1 {
            margin: 3px 0 res(8, 60) 0;
            font-weight: 600;
            font-size: 30px;
            color: $white;
        }

        .team {
            display: flex;
            align-items: center;
            margin-bottom: res(24, 26);

            span {
                position: relative;
                font-family: 'Roboto', sans-serif;
                transform: translateX(-26px);
                line-height: 1.14;

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -20px;
                    transform: translateY(-50%);
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    background-color: $color-light-green;
                }
            }

            img {
                transform: translateX(-17px);

                &:first-child {
                    transform: none;
                }

                &:nth-child(3) {
                    transform: translateX(-32px);
                }
            }
        }
    }

    .backgroundImage {
        position: absolute;
        right: 2.8%;
        z-index: 0;

        .slider {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            color: $colot-compliance;

            .slide {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: opacity $normal, visibility $normal;
                opacity: 0;
                visibility: hidden;

                &.active {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .tabs {
        position: absolute;
        left: 43%;
        bottom: -10%;
        display: flex;
        gap: 5px;
        
        .tab {
            width: 30px;
            height: 5px;
            background: $color-gray;
            border-radius: 20px;
            transition: background-color $normal;
            cursor: pointer;

            &:hover {
                background-color: $color-pink;
            }

            &.active {
                background-color: $color-light-red;
                cursor: auto;
            }
        }
    }

    @include desktop {

        > div {
            position: relative;
            display: flex;
            align-items: center;
        }

        .mainWrapper {
            margin-right: 71px;
        }
        
        .functionsWrapper {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: column;
            width: 264px;
            gap: 20px;

            svg {
                flex-shrink: 0;
            }

            li {
                display: flex;
                padding: 10px 17px 17px 20px;
                background-color: $white;
                box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
                filter: drop-shadow(0px 0px 10px $black);
                border-radius: 4px;
            }

            .text {
                margin-left: 17px;
            }

            h4, p {
                font-family: 'Roboto', sans-serif;
                line-height: 1.1;
            }

            h4 {
                color: #425066;
                margin-bottom: 6px;
            }

            p {
                font-size: 12px;
                color: #898989;
            }
        }

        .backgroundImage {
            font-size: 20px;
        }
    }

    @include desktop-lg {
        background: url('./images/background.jpg') no-repeat center center / 100% 100%;
    }

    @include tablet {
        
        justify-content: center;

        .backgroundImage {
            display: none;

            + .tabs {
                display: none;
            }
        }
    }

    @include mobile {
        background: url('./images/mobileBackground.jpg') no-repeat center center / cover;

        .backgroundImage {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 51px;
            font-size: 16px;
        }

        .functionsWrapper {
            display: none;
        }
    }
}