@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.slider {
    position: relative;
    
    .arrowLeft, .arrowRight {
        position: absolute;
        cursor: pointer;

        path {
            transition: fill $fast;
        }

        &:hover path:first-child {
            fill: $color-gray;
        }
    }

    .arrowLeft {
        top: 26px;
        left: -78px;
    }

    .arrowRight {
        transform: scale(-1, 1);
        right: -74px;
        top: 24px;
    }

    .complianceWrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(200px, 302px));
        gap: 20px 24px;

        li {
            display: flex;
            flex-direction: column;
            padding: 15px 20px 12px;
            align-items: center;
            border-radius: 4px;
            box-shadow: 0px 0px 8px rgba(10, 116, 243, 0.25);
            transition: background-color $normal;

            h4 {
                margin: 7px 0 9px;
                font-weight: 500;
                font-size: 20px;
                color: $black;
            }

            p {
                font-size: 13px;
                line-height: 1.23;
                color: $color-dark-gray;
            }

            h4, p, svg {
                transition: all $fast;
            }

            &.babyBlue {
                background-color: $color-baby-blue;
            }

            &.peach {
                background-color: $color-peach;
            }

            &.pink {
                background-color: $color-pink;
            }

            &.lightBlue {
                background-color: $color-light-blue;
            }

            &.fade-slide {
                transition: opacity 1s ease-in-out;
                opacity: 0;

                &.activeSlide {
                    opacity: 1;
                }
            }
        }
    }

    @include desktop {
        .complianceWrapper {
            li {

                &:first-child, &:nth-child(3) {
                    transform: translate3d(0, -85px, 0);
                }
                
                &:nth-child(2n) {
                    transform: translate3d(0, -135px, 0);
                }
            }

            &.mobileView {
                display: none;
                visibility: hidden;
            }
        }
    }

    @include tablet {
        position: static;

        .complianceWrapper {

            li {
                transform: none !important;
            }
        }

        .arrowLeft {
            left: 13px;
            top: 26%;
        }
    
        .arrowRight {
            transform: scale(-1, 1);
            right: 22px;
            top: 25.4%;
        }
    }

    @include mobile {
        width: 100%;
        overflow-x: auto;
        scroll-behavior: smooth;
        padding-top: 20px;

        .complianceWrapper {
            grid-template-columns: repeat(2, 302px);
            grid-auto-columns: 302px;
            grid-template-rows: repeat(2, 1fr);
            grid-auto-flow: column;

            &.desktopView {
                display: none;
                visibility: hidden;
            }
        }
    }
    
}