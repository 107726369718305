@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

header {
    position: absolute;
    z-index: 100;
    width: 100%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: $white;
    
    @include hover-underline-animation($black);

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px res(19, 15) 7px res(32, 18);
    }

    .logo {
        display: flex;
        align-items: center;

        h2 {
            font-weight: 500;
            font-size: 25px;
            line-height: 1;
            color: $color-red;
        }

        h3 {
            margin-top: 2px;
            font-weight: 600;
            font-size: 12px;
            color: $color-light-black;
        }

        .logoText {
            margin-left: 10px;
        }
    }

    .menu {
        display: flex;
        align-items: center;

        span {
            font-weight: 500;
            color: $color-light-black;
            cursor: pointer;
        }

        > li {
            position: relative;
            margin-right: res(10, 30);
            cursor: pointer;

            &:last-child {
                margin-right: 0;
            }

            > span {
                .arrow {
                    display: inline-block;
                    transition: transform $normal;
                }

                &.active .arrow {
                    transform: rotate(-180deg);
                }
            }
        }

        .arrowMobile {
            display: none;
        }
    }

    .submenu {
        position: absolute;
        bottom: -170px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 150px;
        padding: 20px 0;
        background-color: $white;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        opacity: 0;
        visibility: hidden;
        text-align: center;
        transition: opacity $normal, visibility $normal;

        li {
            height: 20px;
        }

        a {
            display: block;
        }

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    .hamburger {
        display: none;
    }

    @include desktop {
        margin: 20px 19px 0 18px;
        width: calc(100% - 19px*2);

        > div {
            border-radius: 4px;
        }

        .close {
            display: none;
        }

        span:hover .arrow {
            transform: rotate(-360deg);
        }
    }

    @include mobile {

        .hamburger {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            span {
                width: 21px;
                height: 2px;
                margin-bottom: 3px;
                border-radius: 100px;
                background: $black;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateX(100%);
            font-size: 20px;
            background-color: $white;
            transition: transform $normal;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

            .close {
                position: absolute;
                right: 20px;
                top: 20px;
                cursor: pointer;
            }

            .menu {
                flex-direction: column;
                justify-content: center;
                gap: 30px;
                width: 100%;
                height: 100%;
                text-align: center;

                li {
                    margin-right: 0;
                    cursor: auto;

                    > span {
                        display: block;
                        margin-bottom: 10px;
                    }
                }
            }

            .submenu {
                position: initial;
                overflow: hidden;
                opacity: 1;
                visibility: visible;
                width: 100%;
                padding: 0;
                max-height: 0;
                transition: max-height $normal ease-in-out;
                box-shadow: none;

                li {
                    font-size: 16px;
                }

                &.active {
                    max-height: 500px;
                }
            }

            &.active {
                transform: translateX(0);
            }
        }
    }
}