@import './variables';

.action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $color-red;
    border-radius: 4px;
    transition: box-shadow $fast;

    &.main {
        padding: 15px 30px;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.16;
    }

    &.login {
        padding: 5px 15px;
    }

    &:hover {
        box-shadow: 0 0 15px rgba(0,0,0,0.3);
        animation: pulse 1.5s infinite;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0,0,0,0.4);
    }
    70% {
        box-shadow: 0 0 10px 10px rgba(0,0,0,0.1);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0,0,0,0.4);
    }
}