@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.advisors {
    > div {
        display: flex;
        justify-content: space-between;
        padding: 8px res(12, 36) 26px res(20, 25);
    }

    .advisorsImage {
        flex-shrink: 0;
    }

    .advisorsText {
        max-width: 436px;
        margin-top: 130px;

        p {
            max-width: 370px;
            margin: 35px 0 25px;
            font-size: 16px;
            color: $color-advisors-text;
        }
    }

    @include desktop {
        > div {
            border-bottom: 1px solid $color-light-grey;
        }
    }

    @include tablet {
        > div {
            margin-top: 40px;
        }

        .advisorsImage {
            flex-shrink: 1;
        }

        .advisorsText {
            margin-top: 0;
            flex-shrink: 1;

            p {
                max-width: initial;
            }
        }
    }

    @include mobile {
        > div {
            padding-top: 0;
            margin-top: 0;
        }

        .advisorsImage {
            display: none;
        }

        .advisorsText {
            max-width: initial;

            h3 {
                margin-bottom: 13px;
            }

            p {
                margin: 18px 0 27px;
            }
        }
    }
}