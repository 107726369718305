@import 'src/styles/variables';

.titleText {
    h3 {
        font-weight: 300;
        font-size: 40px;
        color: $color-light-black;
    }

    h2 {
        font-weight: 600;
        font-size: 40px;
        color: $color-red;
    }
}