@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.compliance {
    > div {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 388px;
        align-items: flex-start;
        padding: 44px 115px 0 37px;
        border-bottom: 1px solid $color-light-grey;
    }

    .complianceText {
        max-width: 432px;
    }

    h2 {
        margin-bottom: 23px;
    }

    @include desktop {
        .complianceText {
            margin-right: 70px;
            p {
                max-width: 372px;
            }
        }
    }

    @include tablet {
        > div {
            flex-direction: column-reverse;
            align-items: center;
            height: initial;
            padding: 0 0 0 20px;
            border: none;
        }

        .complianceText {
            margin-top: 25px;
            max-width: initial !important;
            text-align: center;
        }

        p {
            max-width: initial !important;
        }
    }

    @include mobile {

        > div {
            transform: translateY(-74px);
        }

        .complianceText {
            text-align: left;
        }

        h3 {
            line-height: 1.5;
        }
        
        h2 {
            line-height: 1.3;
            margin-bottom: 15px;
        }
    }
}