// colors
$white                  : #FFFFFF;
$black                  : #000000;
$color-light-green      : #00FB0C;
$color-dark-green       : #336365;
$color-red              : #DC1F27;
$color-light-red        : #FF385C;
$color-gray             : #D9D9D9;
$color-light-grey       : #DDDDDD;
$color-dark-gray        : #777777;
$color-light-black      : #435065;
$color-baby-blue        : #E6F9FF;
$color-peach            : #FEF2E9;
$color-pink             : #FFEBE6;
$color-light-blue       : #EAF3FE;
$color-advisors-text    : #425066;
$colot-compliance       : #87918C;

// transition
$fast                   : .2s;
$normal                 : .5s;


// breakpoints
$screen-sm-max          : 900px;
$screen-sm-min          : 901px;
$screen-lg-max          : 1280px;
$screen-lg-min          : 1600px;