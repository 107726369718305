@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.platform {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 41px res(13, 41) res(21, 42) res(20, 36);

    > div:first-child {
        text-align: center;
    }

    .platformWrapper {
        display: grid;
        grid-template-columns: minmax(100px, 382px) minmax(100px, 435px) minmax(100px, 393px);
        margin-top: 10px;

        li {
            padding: 28px 10px 39px 0;

            img {
                margin-bottom: res(12, 18);
            }

            h4 {
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 5px;
            }

            p {
                max-width: 369px;
            }
        }
    }

    .button {
        text-align: center;
    }

    @include desktop {
        border-bottom: 1px solid $color-light-grey;

        .platformWrapper {
            li {
                &:nth-child(1),
                &:nth-child(4) {
                    padding-left: 30px;
                }
              
                &:nth-child(2),
                &:nth-child(5) {
                    padding-left: 41px;
                }
              
                &:nth-child(3),
                &:nth-child(6) {
                    padding-left: 37px;
                }

                &:not(:nth-child(3n)) {
                    border-right: 1px solid $color-light-grey;
                }
    
                &:not(:nth-last-child(-n+3)) {
                    border-bottom: 1px solid $color-light-grey;
                }
            }
        }

        .button {
            margin-top: 36px;
        }
    }

    @include mobile {
        padding-top: 0;

        > div:first-child {
            text-align: left;
        }

        .platformWrapper {
            grid-template-columns: 1fr;
            text-align: center;

            li {
                padding: 0px 10px 18px 0;
                border: none;
            }
        }
    }
}