@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.footer {
    @include hover-underline-animation;
    background-color: $color-dark-green;

    > div {
        padding: res(9, 32) 0 res(10, 14);
    }

    .logoWrapper {
        display: flex;

        .logoText {
            margin-left: 8px;
        }

        h2 {
            font-weight: 500;
            font-size: 25px;
            line-height: 1.2;
            color: $color-red;
        }

        h3 {
            font-weight: 600;
            font-size: 12px;
            color: $white;
        }
    }

    .mainWrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 res(16, 88);
        margin-bottom: res(17, 15);
        align-items: center;
        padding-bottom: res(17, 28);
        border-bottom: 1px solid $white;
    }

    .linksWrapper {
        display: flex;
        align-items: flex-start;
        font-family: 'Montserrat', sans-serif;
        color: $white;

        span {
            display: block;
            font-weight: 700;
            margin-bottom: 25px;
        }
    }

    .linkHeader {
        font-size: 24px;
        font-weight: medium;
    }

    .links {
        display: flex;
        flex-direction: column;
        gap: 24px;
        font-size: 15px;
        line-height: 1.2;
        
        a, address {
            font-weight: 500;
        }
    }

    .copyright {
        font-weight: 300;
        text-align: center;
        color: $white;
    }

    @include desktop {
        .linksWrapper {
            
            > li {
                max-width: 232px;

                &:first-child {
                    margin-right: 158px;
                }

                &:last-child {
                    margin-left: 154px;
                }
            }

            span svg {
                display: none;
            }
        }

        .logoWrapper {
            margin-right: 100px;
        }
    }

    @include tablet {
        .linksWrapper {
            width: 100%;
            justify-content: space-between;
            
            > li {

                &:first-child {
                    margin-right: 0;
                }

                &:last-child {
                    margin-left: 0;
                }
            }
        }
    }

    @include mobile {

        .logoWrapper {
            width: 100%;
            padding-bottom: 24px;
            margin-bottom: 24px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        .mainWrapper {
            flex-direction: column;
            align-items: flex-start;
        }

        .linksWrapper {
            width: 100%;
            flex-direction: column;

            li {
                width: 100%;
            }

            > li:last-child ul {
                margin-bottom: 0;
            }

            span {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                cursor: pointer;
            }

            svg {
                transition: transform $normal;
            }

            .active {

                svg {
                    transform: rotate(-180deg);
                }

                > ul {
                    max-height: 500px;
                    padding-top: 20px;
                }
            }
        }

        .links {
            overflow: hidden;
            opacity: 1;
            visibility: visible;
            width: 100%;
            gap: 5px;
            padding: 0;
            max-height: 0;
            margin-bottom: 30px;
            transition: max-height $normal, padding $normal;

            a {
                display: block;
                padding: 5px 0;
                width: 100%;
            }
        }
    }
}