// resize mixin for value
// Example:
// margin: 0 auto res(31, 64) - margin: 0 auto calc(64px + (31 - 64) * ((100vw - 375px) / (1440 - 375)))
@function res($minValue, $maxValue, $symbol: plus, $minWidth: 375, $maxWidth: 1287) {
    @if $symbol == plus {
        @return calc(#{$minValue}px + (#{$maxValue} - #{$minValue}) * ((100vw - #{$minWidth}px) / (#{$maxWidth} - #{$minWidth})));
    } @else {
        @return calc(-1 * (#{$minValue}px + (#{$maxValue} - #{$minValue}) * ((100vw - #{$minWidth}px) / (#{$maxWidth} - #{$minWidth}))));
    }
}

@mixin hover-underline-animation($color: $white) {
    .hoverUnderlineAnimation > li > a {
        display: inline-block;
        position: relative;
    }
      
    .hoverUnderlineAnimation > li > a::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: $color;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
    
    .hoverUnderlineAnimation > li > a {

        @include hover {
            &::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }
}

//only for desktop
@mixin hover {
    @media (hover: hover) and (pointer:fine) {
        &:hover {
            @content;
        }
    }
}