@import 'src/styles/mixin';
@import 'src/styles/variables';
@import 'src/styles/media-queryes';

.reviews {

    > div {
        padding: 23px 76px 0 40px;
        position: relative;
        z-index: 1;

        > div:first-child {
            text-align: center;
        }
    }

    .reviewsWrapper {
        display: flex;
        gap: 30px;
        margin-top: res(60, 90);

        li {
            padding: 49px 16px 5px;
            box-shadow: 0px 0px 8px rgba(10, 116, 243, 0.25);

            p {
                margin-top: 10px;
            }
        }

        .reviewsItemHeader {
            text-align: center;
            position: relative;

            h4 {
                font-weight: 600;
                font-size: 20px;

                + span {
                    color: $color-dark-gray;
                }
            }

            img:first-child {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, -100%);
            }

            span + img {
                display: block;
                margin: 7px auto 30px;
            }
        }
    }

    @include mobile {
        > div {
            padding: 0px 18px 0 23px;
        }

        .reviewsWrapper {
            flex-direction: column;
            gap: 69px;
        }
    }
}