@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@400;500&display=swap');
@import './button';
@import './media-queryes';
@import './variables';
@import './mixin';

/* Reset styles */

*, :before, :after {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

body {
    position: relative;
    min-width: 320px;
    width: 100%;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
}

body, button, ul, ol, h1, h2, h3, h4, h5, h6, p, fieldset, figure, dl, dd, blockquote {
    margin: 0;
}

ul, ol, fieldset, button, th, td {
    padding: 0;
}

li {
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    outline: none;
}

button {
    background-color: transparent;
    cursor: pointer;
}

button, fieldset {
    border: 0;
}

strong, b, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

address, i {
    font-style: inherit;
}

img, svg {
    max-width: 100%;
    height: auto;
}

img {
    vertical-align: top;
    user-select: none;
}

svg {
    display: block;
}

[tabindex],
button {
    &:focus {
        outline: none;
    }
}

::-moz-focus-inner {
    border:0;
}

/* Disable scroll */

.disable-scroll {
    overflow: hidden;
}

.container {
    max-width: 1440px;
    margin: 0 auto;
}